<script>
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import Datepicker from '@/components/Datepicker'
import { Mixin } from '@/mixins/util.js'
import {
  Row,
  Col,
  Container,
  Header,
  Main,
  Table,
  TableColumn,
  Button,
  RadioGroup,
  RadioButton
} from 'element-ui'
export default {
  name: 'mallStoreShipping',
  mixins: [Mixin],
  components: {
    Datepicker,
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-container': Container,
    'el-main': Main,
    'el-header': Header,
    'el-row': Row,
    'el-col': Col,
    'el-radio-group':RadioGroup,
    'el-radio-button':RadioButton,
  },
  data() {
    return{
      startDate: '2019-12-01',
      sessionid:sessionStorage.getItem('session_id'),
      supplier_id:parseInt(sessionStorage.getItem('pub_permissions').substr(1)),
      list:{},
      mall:'001_000033_000_00000000',
      selectDate:{
        y:null,
        m:null
      },
      timer:null,
      mall_options:[
        {
          key:'001_000033_000_00000000',
          label:'MOMO'
        },
        {
          key:'001_000033_001_00000000',
          label:'MOMO廠配'
        },
        {
          key:'001_000033_002_00000000',
          label:'MO店+'
        },
        {
          key:'001_000035_000_00000000',
          label:'YAHOO'
        },
        {
          key:'001_000036_000_00000000',
          label:'SHOPEE'
        },
        {
          key:'001_000041_000_00000000',
          label:'PCHOME'
        },
        {
          key:'001_000041_001_00000000',
          label:'PCHOME 廠配'
        },
        {
          key:'001_000043_000_00000000',
          label:'ETMALL 廠配'
        },
        {
          key:'001_000043_001_00000000',
          label:'ETMALL TV'
        },
        {
          key:'001_000048_000_00000000',
          label:'FRIDAY'
        }
      ]
    }
  },
  computed:{
    d(){
      const d = [],sumRow = {shipping_date:'合計',sumOfQuantity:0,sumOfAmount:0}

      for (const date of this.dateList) {
        let item = {shipping_date:date,sumOfQuantity:0,sumOfAmount:0}
        const filterDate = this.rawData.filter(d=>d.shipping_date===date)
        for (const code of this.setCodeList) {
          const filtersetCode = filterDate.filter(d=>d.product_set_code===code)
          if(sumRow[code]===undefined)
            sumRow[code] = {quantity:0,amount:0}
          item[code] = filtersetCode.reduce((a,c)=>{
            a.quantity+=c.product_set_quantity
            a.amount+=c.product_set_cost_amount
            return a
          },{quantity:0,amount:0})
          item.sumOfQuantity+=item[code].quantity
          item.sumOfAmount+=item[code].amount

          sumRow[code].quantity += item[code].quantity
          sumRow[code].amount += item[code].amount
        }
        sumRow.sumOfQuantity += item.sumOfQuantity 
        sumRow.sumOfAmount += item.sumOfAmount 
        d.push(item)
      }
      d.unshift(sumRow)
      return d
    },
    rawData(){
      if(!this.list[this.mall]) return []
      return this.list[this.mall].map(d=>{
        return{
          shipping_date:d[0].split(' ')[0],
          order_nid:d[1],
          product_set_code:d[2],
          product_set_name  :d[3],
          product_set_quantity:d[4]*1,
          product_set_cost_amount:Math.round(d[5]*1),
        }
      })
    },
    setCodeList(){
      return this.rawData.reduce((a,c)=>{
        if(!a.includes(c.product_set_code))
          a.push(c.product_set_code)
        return a
        },[])
    },
    setNameList(){
      // return this.rawData.reduce((a,c)=>{
      //   if(!a.includes(c.product_set_name))
      //     a.push(c.product_set_name)
      //   return a
      //   },[])
      return this.setCodeList.map(d=>{
        const {product_set_name} = this.rawData.find(raw=>raw.product_set_code===d)
        return product_set_name
      })
    },
    dateList(){
      let list = [],{y,m} = this.selectDate
      const lastDay = new Date(y,m,0).getDate()
      for(let i=1;i<=lastDay;i++){
        const month = m<10?`0${m}`:m
        const day = i<10?`0${i}`:i
        list.push(`${y}-${month}-${day}`)
      }
      return list
    }
  },
  methods:{
    getChannelList(){
      const url = 'https://www.bamb.asia/app/hermers/channel/list'
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8',
        },
        body: `sessionid=${this.sessionid}&system_type=5`,
      }

      fetch(url, config)
          .then(res => res.text())
          .then(rep => {
            const data = JSON.parse(this.RemoveNonValidJSONChars(rep))
            data.records.map(d=>{
              return{
                key:d[0],
                label:d[2]
              }
            })
          })
    },
    ssibalshakeit(){
      const sheet_name = this.mall_options.find(d=>d.key === this.mall).label
      const workbook = new Excel.Workbook()
      const worksheet = workbook.addWorksheet(sheet_name);

      //add header
      let first_header = ['出荷日','每日集計',''],second_header = ['','',''],third_header=['','出荷數','流通高']
      for (const [index, code] of this.setCodeList.entries()) {
        first_header.push(...[code,''])
        second_header.push(...[this.setNameList[index],''])
        third_header.push(...['出荷數','流通高'])
      }
      worksheet.addRow(first_header)
      worksheet.addRow(second_header)
      worksheet.addRow(third_header)
      //

      //add content
      for (const d of this.d) {
        //shipping_date sumOfQuantity sumOfAmount {quantity amount}
        let item = [d.shipping_date,d.sumOfQuantity,d.sumOfAmount]
        for (const code of this.setCodeList) {
          item.push(d[code].quantity)
          item.push(d[code].amount)
        }
        worksheet.addRow(item)
      }
      //
      //merge header & alignment
      for(let index = 0;index<this.setCodeList.length;index++){
        worksheet.mergeCells(1,4+index*2,1,4+index*2+1)
        worksheet.mergeCells(2,4+index*2,2,4+index*2+1)
      }
      worksheet.mergeCells(1,1,3,1)
      worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.mergeCells(1,2,2,3)
      worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' }
      //

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `${sheet_name}出荷集計.xlsx`);
      });
    },
    cb(){
      this.$eventBus.$emit('loadingHandler', true)
      this.timer = setTimeout(()=>{
        this.$eventBus.$emit('loadingHandler', false)
      },300)
    },
    async getDataHandler(...arg) {
      this.selectDate.y= arg[0]
      this.selectDate.m= arg[1]
      this.list = await this.getMallShippingList(arg[0],arg[1])
      if (this.sessionid === '3294228999') {
        for (const option of this.mall_options) {
          this.list[option.key].map((d,idx)=>{
            d[2] = `fake_id${idx}`
            d[3] = `fake_name${idx}`
            return d
          })
        }
      }
      // console.log(this.list)
    },
    async getMallShippingList(year,month){
      const monthWithZero = month<10?`0${month}`:month
      const lastday = new Date(year, month, 0).getDate()

      // console.log(`${year}-${monthWithZero}-01`,`${year}-${monthWithZero}-${lastday}`)
      const begin = `${year}-${monthWithZero}-01`,end=`${year}-${monthWithZero}-${lastday}`
      const url ='https://www.bamb.asia/app/hermers/all_mall_ship_list'
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8',
        },
        body: `sessionid=${this.sessionid}&
        supplier_uid=${this.supplier_id || ''}&
        begin_date=${begin}&
        end_date=${end}`,
      }

      return await fetch(url, config)
          .then(res => res.text())
          .then(async rep => {
            const data = JSON.parse(this.RemoveNonValidJSONChars(rep))
            return data
          })
      
    },
    priceFormatter(row, column, cellValue) {
      if (cellValue===0) return cellValue
      if (cellValue) return (cellValue * 1).toLocaleString()
    },
  },
  mounted(){
    const menu = document.querySelector('.menu')
    const ham = document.querySelector('.ham')
    menu.style.left = '-320px'
    ham.style.display = 'block'
    const date = new Date()
    this.selectDate.y = date.getFullYear()
    this.selectDate.m = date.getMonth()+1
    this.getDataHandler(date.getFullYear(),date.getMonth()+1)
    // this.getChannelList()
  },
  beforeDestroy(){
    clearTimeout(this.timer)
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
